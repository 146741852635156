import React, { useCallback } from 'react';
import T from 'ecto-common/lib/lang/Language';
import { AlarmEventModel, AlarmModel } from 'ecto-common/lib/API/AlarmsAPIGen';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import {
  TimeFormats,
  getDefaultDateTimeFormat,
  getDefaultTimeFormat
} from 'ecto-common/lib/utils/dateUtils';

const StartDateColumn = ({
  alarm,
  alarmDate,
  showAlarmHistory
}: {
  alarm: AlarmModel;
  alarmDate: string;
  showAlarmHistory: (sourceUri: string, occuredAt?: string) => void;
}) => {
  const date = moment.tz(alarmDate, DEFAULT_TIMEZONE);
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      showAlarmHistory(alarm.sourceUri, (alarm as AlarmEventModel)?.startDate);
    },
    [showAlarmHistory, alarm]
  );

  return (
    <TableColumn
      title={
        <a href="#" onClick={onClick}>
          {date.format(getDefaultDateTimeFormat(TimeFormats.NONE))}{' '}
        </a>
      }
      subtitle={T.format(
        T.alarms.datesubtitleformat,
        date.format(getDefaultTimeFormat(TimeFormats.LONG_TIME))
      )}
    />
  );
};
export default StartDateColumn;
