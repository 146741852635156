import { createAction } from 'ecto-common/lib/utils/actionUtils';
import UUID from 'uuidjs';
import APIGen, {
  CreateBuildingByTemplateDataResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AdminRootState } from '../../reducers/storeAdmin';
import { QueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { BatchedGetNodesQueryKey } from 'ecto-common/lib/hooks/useCurrentNode';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import { updateGetNodeCache } from 'ecto-common/lib/LocationTreeView/useNodeTree';
export const SET_PROVISIONING_DEVICE_DATA_CHANGED =
  'SET_PROVISIONING_DEVICE_DATA_CHANGED';

export const ProvisioningActions = {
  notifyDeviceDataChanged: createAction(SET_PROVISIONING_DEVICE_DATA_CHANGED)
};

export enum CreateError {
  BUILDING_NAMING_CONFLICT = 'BUILDING_NAMING_CONFLICT',
  SITE_NAMING_CONFLICT = 'SITE_NAMING_CONFLICT',
  MISSING_CONNECTION = 'MISSING_CONNECTION',
  GENERIC = 'GENERIC'
}

export const instantiateBuildingTemplate = async (
  contextSettings: ApiContextSettings,
  node: NodeV2ResponseModel,
  { equipmentTemplateForm }: AdminRootState
): Promise<CreateBuildingByTemplateDataResponseModel[]> => {
  const {
    equipmentTemplateGroupId,
    equipmentTemplateInstanceOverrides,
    connectionModbusConfigOverride,
    existingEnergyManagerDeviceId
  } = equipmentTemplateForm;

  const templateData = {
    nodeId: node.nodeId,
    equipmentTemplateGroupId,
    connectionModbusConfigOverride,
    equipmentTemplateInstanceOverrides,
    deviceId: existingEnergyManagerDeviceId ?? UUID.generate()
  };

  const instructions =
    await APIGen.AdminBuildings.getAddOrUpdateBuildingsByTemplates.promise(
      contextSettings,
      [templateData],
      null
    );

  return await APIGen.AdminBuildings.addOrUpdateBuildingsByTemplates.promise(
    contextSettings,
    instructions,
    null
  );
};

export const updateNodeTreeIncrementallyFromDelete = (
  deletedNodeId: string,
  deletedNodeParentId: string | null,
  queryClient: QueryClient,
  removeNodesWithIds: (nodeIds: string[]) => void,
  contextSettings: ApiContextSettings
) => {
  queryClient.removeQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: _.compact([deletedNodeParentId])
    })
  });

  removeNodesWithIds([deletedNodeId]);

  queryClient.removeQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: [deletedNodeId]
    })
  });

  queryClient.removeQueries({
    queryKey:
      APIGen.NodesV2.getNodeWithAncestorsAndSiblings.path(contextSettings)
  });
};

export function resetCacheForNode(
  contextSettings: ApiContextSettings,
  parentId: string,
  queryClient: QueryClient
) {
  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: [parentId]
    })
  });

  queryClient.invalidateQueries({
    queryKey:
      APIGen.NodesV2.getNodeWithAncestorsAndSiblings.path(contextSettings)
  });

  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
  });

  queryClient.invalidateQueries({
    queryKey: [BatchedGetNodesQueryKey]
  });
}

export function patchNodes(
  contextSettings: ApiContextSettings,
  changedNodes: NodeV2ResponseModel[],
  queryClient: QueryClient
) {
  for (const node of changedNodes) {
    resetCacheForNode(contextSettings, node.parentId, queryClient);
  }
}

export const updateNodeTreeIncrementally = (
  contextSettings: ApiContextSettings,
  parentNodeId: string | null,
  queryClient: QueryClient
) => {
  queryClient.invalidateQueries({
    queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: _.compact([parentNodeId])
    })
  });
};

export async function updateNodeTreeAfterNodeAddedOrUpdated(
  contextSettings: ApiContextSettings,
  parentId: string,
  nodeId: string,
  queryClient: QueryClient,
  addNodes: (nodes: NodeV2ResponseModel[]) => void,
  allNodesMap: Record<string, NodeV2ResponseModel>
) {
  if (parentId !== ROOT_NODE_ID) {
    const parent = await APIGen.NodesV2.getNodesByIds.promise(
      contextSettings,
      {
        nodeIds: [parentId]
      },
      null
    );

    const newChildren = await APIGen.NodesV2.getNodeChildren.promise(
      contextSettings,
      {
        nodeIds: [parentId]
      },
      null
    );

    const allNew = [...parent.nodes, ...newChildren];
    updateGetNodeCache(allNew, allNodesMap, contextSettings, queryClient);

    addNodes(allNew);

    const queryKey = APIGen.NodesV2.getNodeChildren.path(contextSettings, {
      nodeIds: [parentId]
    });
    queryClient.setQueryData(queryKey, newChildren);

    const queryKeyParent = APIGen.NodesV2.getNodesByIds.path(contextSettings, {
      nodeIds: [parentId]
    });

    queryClient.setQueryData(queryKeyParent, parent);

    queryClient.invalidateQueries({
      queryKey: APIGen.NodesV2.getNodeChildren.path(contextSettings, {
        nodeIds: [parentId]
      })
    });
  }

  if (nodeId != null) {
    const nodes = await APIGen.NodesV2.getNodesByIds.promise(
      contextSettings,
      {
        nodeIds: [nodeId]
      },
      null
    );
    const queryKey = APIGen.NodesV2.getNodesByIds.path(contextSettings, {
      nodeIds: [nodeId]
    });
    queryClient.setQueryData(queryKey, nodes);

    const updatedOrAddedNode = nodes.nodes?.[0];
    if (updatedOrAddedNode != null) {
      addNodes([updatedOrAddedNode]);
    }

    return updatedOrAddedNode;
  }

  return null;
}
