type ListQuery = {
  nodeId: string;
  orderBy?: string;
  sortDirection?: 'asc' | 'desc';
  searchFilter?: string;
};

type ListQueryResult = {
  $filter: string;
  $orderby: string;
  $top: number;
};

export const createAlarmListQuery = ({
  nodeId,
  orderBy,
  sortDirection,
  searchFilter
}: ListQuery): ListQueryResult => {
  const baseFilter = `nodePath/any(d:contains(d/id,'${nodeId}')) and (isActive eq true or (isActive eq false and acknowledgedAt eq null))`;
  const filter = searchFilter
    ? `${baseFilter} and properties/any(pair: pair/key eq 'signalName' and contains(tolower(pair/value),tolower('${searchFilter}')) or pair/key eq 'signalDescription' and contains(tolower(pair/value),tolower('${searchFilter}')))`
    : baseFilter;
  return {
    $filter: filter,
    $orderby:
      orderBy != null && sortDirection != null
        ? orderBy + ' ' + sortDirection
        : undefined,
    $top: 20
  };
};

type EventListQuery = ListQuery & {
  sourceUri?: string;
};

export const createAlarmListEventQuery = ({
  nodeId,
  orderBy,
  sortDirection,
  searchFilter,
  sourceUri
}: EventListQuery): ListQueryResult => {
  const baseFilter = `nodePath/any(d:contains(d/id,'${nodeId}'))`;
  let filter = searchFilter
    ? `${baseFilter} and properties/any(pair: pair/key eq 'signalName' and contains(tolower(pair/value),tolower('${searchFilter}')) or pair/key eq 'signalDescription' and contains(tolower(pair/value),tolower('${searchFilter}')))`
    : baseFilter;
  if (sourceUri) {
    filter = `${filter} and sourceUri eq '${sourceUri}'`;
  }
  return {
    $filter: filter,
    $orderby:
      orderBy != null && sortDirection != null
        ? orderBy + ' ' + sortDirection
        : undefined,
    $top: 20
  };
};
