import React, { useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';

import {
  NodeV2ResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from '../datasources/LastSignalValuesDataSource';
import {
  MatchedSignal,
  MatchedSignalInput
} from '../datasources/SignalValuesDataSource';
import { EmptySignalType } from 'ecto-common/lib/utils/constants';
import TimeRangeContext, {
  TimeRangeContextType
} from '../context/TimeRangeContext';
import T from 'ecto-common/lib/lang/Language';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { getNodePage } from 'ecto-common/lib/utils/commonLinkUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import { formatNumber } from 'ecto-common/lib/utils/stringUtils';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import { Property } from 'csstype';

import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';
import {
  ASC,
  SortDirectionType
} from 'ecto-common/lib/DataTable/SortDirection';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';

const labelForSignalRule = (
  isLoading: boolean,
  matchingSignals: MatchedSignal[],
  signalRule: SignalInputType,
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalUnitTypesMap: Record<string, UnitResponseModel>
) => {
  const matchingSignal = _.find(matchingSignals, [
    'signal.signalTypeId',
    signalRule.signalTypeId
  ])?.signal;

  if (isLoading) {
    return T.common.loading;
  }
  const signalType = signalTypesMap[signalRule.signalTypeId] ?? EmptySignalType;

  const unit =
    signalUnitTypesMap[signalTypesMap[matchingSignal?.signalTypeId]?.unitId]
      ?.unit;

  return (
    (!_.isEmpty(signalRule?.displayName)
      ? signalRule.displayName
      : signalType.name) +
    ' (' +
    unit +
    ')'
  );
};

const NodeCell = ({ node }: { node: NodeV2ResponseModel }) => {
  const { tenantId } = useContext(TenantContext);
  return <NavLink to={getNodePage(tenantId, node)}>{node?.name}</NavLink>;
};

type SignalValue = {
  signalInfo: MatchedSignalInput;
  signalValueInfo: { value: number; time?: string; step?: number };
  unit: string;
};

// Composes the signal value for a specific node and signal type
const signalValue = ({
  nodeId,
  signals,
  signalTypeId,
  signalValueMap,
  signalTypesMap,
  signalUnitTypesMap
}: {
  nodeId: string;
  signals: LastSignalValuesDataSourceResult;
  signalTypeId: string;
  signalValueMap: Record<
    string,
    { value: number; time?: string; step?: number }
  >;
  signalTypesMap: Record<string, SignalTypeResponseModel>;
  signalUnitTypesMap: Record<string, UnitResponseModel>;
}): SignalValue => {
  const selectedSignals = _.filter(signals?.signalInfo?.matchingSignals, [
    'signal.signalTypeId',
    signalTypeId
  ]);
  const signalInfo = signals?.signalInfo;

  const _selectedSignal = _.find(
    selectedSignals,
    ({ signal }) =>
      signalInfo?.nodeIdToSignal[nodeId]?.[signal.signalId] != null
  );
  const unit = getSignalTypeUnit(
    signalTypeId,
    signalTypesMap,
    signalUnitTypesMap
  );

  const signal = _selectedSignal?.signal;
  const signalValueInfo = signalValueMap[signal?.signalId];
  return {
    signalInfo: _selectedSignal?.signalInfo,
    signalValueInfo,
    unit
  };
};

const SignalValueCell = ({
  signalInfo,
  signalValueInfo,
  unit
}: SignalValue) => {
  const valueStr = formatNumber(
    signalValueInfo?.value,
    signalInfo?.rounding ?? numDecimalsForUnit(unit)
  );

  let tooltipText = null;
  if (signalValueInfo?.step || signalValueInfo?.time) {
    let timeVal = '-';

    if (signalValueInfo.time) {
      timeVal = moment(signalValueInfo.time)
        .tz(DEFAULT_TIMEZONE)
        .format(getDefaultDateTimeFormat(TimeFormats.SECONDS));
    }

    tooltipText = T.format(
      T.equipment.tooltipformat,
      <strong key="time-text">{timeVal} </strong>,
      <strong key="step-text">{signalValueInfo.step || '-'}</strong>
    );
  } else {
    tooltipText = T.equipment.novalueset;
  }

  return (
    <CopyToClipboardTooltip
      valueToCopy={'' + valueStr}
      additionalText={tooltipText}
    >
      {valueStr}
    </CopyToClipboardTooltip>
  );
};

// Type of each row in the data table
type NodeSignalValue = {
  node: NodeV2ResponseModel;
  signals: Record<string, SignalValue>;
};

const columns: DataTableColumnProps<NodeSignalValue>[] = [
  {
    dataKey: 'node.name',
    canSort: true,
    label: T.common.name,
    dataFormatter: (_unused: string, value: NodeSignalValue) => {
      return <NodeCell node={value.node} />;
    }
  }
];

const SignalsTable = ({
  nodeList,
  signals
}: {
  /**
   * An array of nodes that will be listed with the signal values
   */
  nodeList: NodeV2ResponseModel[];
  /**
   * A object with collected signal information from the selected signals
   */
  signals: LastSignalValuesDataSourceResult;
}) => {
  const timeRange = useContext<TimeRangeContextType>(TimeRangeContext);
  const timeRangeOption = timeRange?.timeRangeOption;

  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const { items, columns: _columns } = useMemo(() => {
    const signalValueMap = _.keyBy(signals?.signalValues, 'signalId');
    const inputsFilteredByTimeOption = _.filter(
      signals?.signalInfo?.signalInputs,
      (input) => {
        if (input?.timeRange) {
          return input.timeRange === timeRangeOption;
        }

        return input != null;
      }
    );

    // Create new items with the signal values for each node
    const newItems = _.map(nodeList, (node) => {
      return {
        node,
        signals: _.reduce(
          inputsFilteredByTimeOption,
          (result, signal) => {
            return {
              ...result,
              // Map signal type id for this node to the correct signal and value
              [signal.signalTypeId]: signalValue({
                nodeId: node.nodeId,
                signals,
                signalTypeId: signal.signalTypeId,
                signalValueMap,
                signalTypesMap,
                signalUnitTypesMap
              })
            };
          },
          {}
        )
      };
    });

    // Create new columns for each signal type, dataKey is "signal.<signalTypeId>"
    const newColumns = _.map(inputsFilteredByTimeOption, (signal) => ({
      dataKey: 'signals.' + signal.signalTypeId,
      canSort: true,
      align: 'right' as Property.TextAlign,
      label: labelForSignalRule(
        signals?.isLoading,
        signals?.signalInfo?.matchingSignals,
        signal,
        signalTypesMap,
        signalUnitTypesMap
      ),
      dataFormatter: (rowSignalValue: SignalValue) => {
        return <SignalValueCell {...rowSignalValue} />;
      }
    }));

    return {
      items: newItems,
      columns: [...columns, ...newColumns]
    };
  }, [signals, nodeList, timeRangeOption, signalTypesMap, signalUnitTypesMap]);

  const [sort, setSort] = useState<{
    sortBy: string;
    sortDirection: SortDirectionType;
  }>({ sortBy: 'node.name', sortDirection: ASC });

  const sortedItems = useMemo(() => {
    // Special case for sorting by signal value
    if (sort.sortBy !== 'node.name') {
      return _.orderBy(
        items,
        (item) => {
          return _.get(item, sort.sortBy)?.signalValueInfo?.value;
        },
        sort.sortDirection
      );
    }
    const result = sortByLocaleCompare(items, sort.sortBy);
    if (sort.sortDirection === ASC) {
      return result;
    }
    return result.reverse();
  }, [items, sort.sortBy, sort.sortDirection]);

  return (
    <DataTable
      data={sortedItems}
      columns={_columns}
      sortBy={sort?.sortBy}
      sortDirection={sort?.sortDirection}
      onSortChange={(newSortBy, newSortDirection) => {
        setSort({
          sortBy: newSortBy,
          sortDirection: newSortDirection as SortDirectionType
        });
      }}
    />
  );
};

export default SignalsTable;
