import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import ConfirmDeleteDialog, {
  ConfirmDeleteDialogProps
} from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import ModelFormDialog from '../ModelForm/ModelFormDialog';
import ToolbarContentPage from '../ToolbarContentPage/ToolbarContentPage';
import { useSimpleDialogState } from '../hooks/useDialogState';
import T from '../lang/Language';
import { ModelDefinition } from '../ModelForm/ModelPropType';
import DataTable, { DataTableColumnProps } from '../DataTable/DataTable';
import _ from 'lodash';
import { standardColumns } from '../utils/dataTableUtils';
import { toastStore } from '../Toast/ToastContainer';
import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult
} from '@tanstack/react-query';
import DataTableLoadMoreFooter from '../DataTable/DataTableLoadMoreFooter';
import { ASC, SortDirectionType } from '../DataTable/SortDirection';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';

export type ODataListQuery = {
  $filter?: string;
  $orderby?: string;
  $top?: number;
};

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

const getItemName = <T extends object>(
  item: T,
  itemName: StringKeys<T>
): string => {
  return _.get(item, itemName as keyof object);
};

export type CRUDListResponseType<ValueType extends object> = {
  items?: ValueType[];
  continuationToken?: string;
};

export type CRUDSortData<ValueType extends object> = {
  sortBy: keyof ValueType;
  sortDirection: SortDirectionType;
};

type CrudListRequestInfo = {
  refetch: () => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  hasError: boolean;
  isLoading: boolean;
};

type SimpleCrudQueryDataType<ValueType extends object> = {
  items: ValueType[];
};

export function useSimpleCrudViewData<ValueType extends object>({
  searchItems,
  sortBy,
  sortDirection,
  listQuery
}: {
  sortBy?: keyof ValueType;
  sortDirection?: SortDirectionType;
  searchItems?: (keyof ValueType)[];
  listQuery: UseQueryResult<SimpleCrudQueryDataType<ValueType>, unknown>;
}) {
  const queryParams = queryString.parse(location.search);
  const search = queryParams.search as string;
  const navigate = useNavigate();
  const [sort, setSort] = useState<CRUDSortData<ValueType>>(
    sortBy ? { sortBy, sortDirection: sortDirection || ASC } : null
  );

  const setSearch = useCallback(
    (newSearch: string) => {
      if (newSearch !== '') {
        navigate(
          {
            search: '?' + queryString.stringify({ search: newSearch })
          },
          { replace: true }
        );
      } else {
        navigate({ search: null }, { replace: true });
      }
    },
    [navigate]
  );

  const allItems = useMemo(() => {
    let ret = listQuery.data?.items ?? [];
    if (!_.isEmpty(search)) {
      ret = _.filter(ret, (item) =>
        _.some(searchItems, (searchItem) =>
          _.includes(
            _.toLower(_.get(item, searchItem) as unknown as string),
            _.toLower(search)
          )
        )
      );
    }

    return _.orderBy(ret, [sort.sortBy], [sort.sortDirection]);
  }, [listQuery.data, search, searchItems, sort.sortBy, sort.sortDirection]);

  return {
    setSearch,
    allItems,
    sort,
    setSort,
    listQuery: {
      data: listQuery.data,
      isLoading: listQuery.isLoading,
      hasError: listQuery.error != null,
      refetch: listQuery.refetch,
      isFetchingNextPage: false,
      hasNextPage: false,
      fetchNextPage: _.noop
    }
  };
}

// This is a helper function to check if an error is a conflict error
// We should improve the typing of the errors to avoid these kind of checks
function errorIsConflict(a: unknown) {
  if (_.isObject(a) && 'response' in a) {
    const response = a.response;
    if (
      _.isObject(response) &&
      'status' in response &&
      _.isNumber(response.status)
    ) {
      return response.status === HttpStatus.CONFLICT;
    }
  }

  return false;
}

export function useCrudViewData<ValueType extends object>({
  listQueryHook,
  searchItems,
  sortBy,
  sortDirection
}: {
  sortBy?: keyof ValueType;
  sortDirection?: SortDirectionType;
  searchItems?: (keyof ValueType)[];
  listQueryHook: (
    query: ODataListQuery
  ) => UseInfiniteQueryResult<
    InfiniteData<CRUDListResponseType<ValueType>, unknown>,
    unknown
  >;
}) {
  const queryParams = queryString.parse(location.search);
  const search = queryParams.search as string;
  const navigate = useNavigate();
  const [sort, setSort] = useState<CRUDSortData<ValueType>>(
    sortBy ? { sortBy, sortDirection: sortDirection || ASC } : null
  );

  const setSearch = useCallback(
    (newSearch: string) => {
      if (newSearch !== '') {
        navigate(
          {
            search: '?' + queryString.stringify({ search: newSearch })
          },
          { replace: true }
        );
      } else {
        navigate({ search: null }, { replace: true });
      }
    },
    [navigate]
  );

  const searchFilter = !_.isEmpty(search)
    ? _.join(
        _.map(
          searchItems,
          (item: string) => `contains(tolower(${item}), tolower('${search}'))`
        ),
        ' or '
      )
    : '';

  const listQuery = listQueryHook({
    ...(_.isEmpty(searchFilter) ? {} : { $filter: searchFilter }),
    ...(_.isEmpty(sort)
      ? {}
      : { $orderby: `${String(sort.sortBy)} ${sort.sortDirection}` }),
    $top: 20
  });

  const allItems = useMemo(() => {
    return _.compact(_.flatMap(listQuery.data?.pages, (item) => item.items));
  }, [listQuery.data?.pages]);

  return {
    setSearch,
    allItems,
    sort,
    setSort,
    listQuery: {
      refetch: listQuery.refetch,
      isFetchingNextPage: listQuery.isFetchingNextPage,
      hasNextPage: listQuery.hasNextPage,
      fetchNextPage: listQuery.fetchNextPage,
      hasError: listQuery.error != null,
      isLoading: listQuery.isLoading
    }
  };
}

export type CRUDViewModelEnvironment = {
  isNew: boolean;
};

const CRUDView = <
  ValueType extends object,
  UpdateErrorType extends object,
  CreateErrorType extends object
>({
  models,
  columns,
  createNewItem,
  itemName,
  searchItems,
  title,
  editTitle,
  addTitle,
  onAdded,
  onUpdated,
  deleteItemMutation,
  updateItemMutation,
  createItemMutation,
  toolbarItems,
  onClickRow,
  listQuery,
  sort,
  setSort,
  allItems,
  setSearch,
  confirmDeleteDialog
}: {
  models: ModelDefinition<ValueType, CRUDViewModelEnvironment>[];
  columns: DataTableColumnProps<ValueType>[];
  createNewItem: () => ValueType;
  itemName: StringKeys<ValueType>;
  searchItems?: (keyof ValueType)[];
  title: string | React.ReactElement;
  editTitle: string | string[];
  addTitle: string;
  deleteItemMutation: UseMutationResult;
  updateItemMutation: UseMutationResult<unknown, UpdateErrorType, ValueType>;
  createItemMutation: UseMutationResult<unknown, CreateErrorType, ValueType>;
  toolbarItems?: React.ReactNode;
  onClickRow?: Parameters<typeof DataTable<ValueType>>[0]['onClickRow'];
  onAdded?: (item: ValueType) => void;
  onUpdated?: (item: ValueType) => void;
  listQuery: CrudListRequestInfo;
  sort: CRUDSortData<ValueType>;
  setSort: Dispatch<SetStateAction<CRUDSortData<ValueType>>>;
  allItems: ValueType[];
  setSearch: (newSearch: string) => void;
  confirmDeleteDialog?: React.ComponentType<
    ConfirmDeleteDialogProps & { item?: ValueType }
  >;
}) => {
  const [editItem, setEditItem] = useState<ValueType>(null);
  const [isNewItem, setIsNewItem] = useState(false);

  const resetEditItem = useCallback(() => {
    setEditItem(null);
    setIsNewItem(false);
  }, []);

  const createItem = useCallback(() => {
    setEditItem(createNewItem());
    setIsNewItem(true);
  }, [createNewItem]);

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    useSimpleDialogState();

  const [confirmDeleteItem, _setConfirmDeleteItem] = useState<ValueType>(null);

  const setConfirmDeleteItem = useCallback(
    (item: ValueType) => {
      _setConfirmDeleteItem(item);
      if (item) {
        openDeleteDialog();
      } else {
        closeDeleteDialog();
      }
    },
    [closeDeleteDialog, openDeleteDialog]
  );

  const onSuccessUpdateItem = useCallback(
    (_unused: unknown, item: ValueType) => {
      resetEditItem();
      toastStore.addSuccessToastForUpdatedItem(
        getItemName(item, itemName),
        isNewItem
      );
      onUpdated?.(item);
      listQuery.refetch();
    },
    [isNewItem, itemName, listQuery, onUpdated, resetEditItem]
  );

  const onErrorUpdateItem = useCallback(
    (error: UpdateErrorType, item: ValueType) => {
      let suffix = '';

      if (errorIsConflict(error)) {
        suffix = ' - ' + T.common.conflicterror;
      }

      toastStore.addErrorToastForUpdatedItem(
        getItemName(item, itemName) + suffix,
        isNewItem
      );
    },
    [isNewItem, itemName]
  );
  const onSuccessCreateItem = useCallback(
    (_unused: unknown, item: ValueType) => {
      toastStore.addSuccessToastForUpdatedItem(
        getItemName(item, itemName),
        true
      );
      onAdded?.(item);
      resetEditItem();
      listQuery.refetch();
    },
    [itemName, onAdded, resetEditItem, listQuery]
  );

  const onErrorCreateItem = useCallback(
    (error: CreateErrorType, item: ValueType) => {
      let suffix = '';
      if (errorIsConflict(error)) {
        suffix = ' - ' + T.common.conflicterror;
      }

      toastStore.addErrorToastForUpdatedItem(
        getItemName(item, itemName) + suffix,
        isNewItem
      );
    },
    [isNewItem, itemName]
  );
  const onSuccessDeleteItem = useCallback(() => {
    toastStore.addSuccessToastForDeletedItem(
      getItemName(confirmDeleteItem, itemName)
    );
    resetEditItem();
    setConfirmDeleteItem(null);

    listQuery.refetch();
  }, [
    confirmDeleteItem,
    itemName,
    listQuery,
    resetEditItem,
    setConfirmDeleteItem
  ]);

  const onErrorDeleteItem = useCallback(() => {
    toastStore.addErrorToastForDeletedItem(
      getItemName(confirmDeleteItem, itemName)
    );
  }, [confirmDeleteItem, itemName]);

  const isLoading =
    updateItemMutation.isPending ||
    deleteItemMutation.isPending ||
    createItemMutation.isPending;

  const _columns = useMemo(() => {
    return [
      ...columns,
      ...standardColumns<ValueType>({
        onDelete: (item: ValueType) => setConfirmDeleteItem(item),
        onEdit: (item: ValueType) => setEditItem(_.cloneDeep(item))
      })
    ];
  }, [columns, setConfirmDeleteItem]);

  const ConfirmDelete = confirmDeleteDialog || ConfirmDeleteDialog;

  const environment = useMemo(() => ({ isNew: isNewItem }), [isNewItem]);

  return (
    <ToolbarContentPage
      showLocationPicker={false}
      wrapContent={false}
      title={title}
      addAction={() => createItem()}
      addActionTitle={addTitle}
      onSearchInput={!_.isEmpty(searchItems) ? setSearch : undefined}
      toolbarItems={toolbarItems}
    >
      <ConfirmDelete
        isOpen={isDeleteDialogOpen}
        onModalClose={() => setConfirmDeleteItem(null)}
        isLoading={deleteItemMutation.isPending}
        item={confirmDeleteItem}
        itemName={getItemName(confirmDeleteItem, itemName)}
        onDelete={() =>
          deleteItemMutation.mutate(confirmDeleteItem, {
            onSuccess: onSuccessDeleteItem,
            onError: onErrorDeleteItem
          })
        }
      />
      <ModelFormDialog<ValueType, false, CRUDViewModelEnvironment>
        onModalClose={resetEditItem}
        input={editItem}
        editTitle={editTitle}
        addTitle={addTitle}
        actionText={isNewItem ? T.common.add : T.common.save}
        isSavingInput={isLoading}
        saveInput={
          isNewItem
            ? (item) =>
                createItemMutation.mutate(item, {
                  onSuccess: onSuccessCreateItem,
                  onError: onErrorCreateItem
                })
            : (item) =>
                updateItemMutation.mutate(item, {
                  onSuccess: onSuccessUpdateItem,
                  onError: onErrorUpdateItem
                })
        }
        models={models}
        isObjectNew={() => isNewItem}
        saveAsArray={false}
        environment={environment}
      />
      <DataTable<ValueType>
        onClickRow={onClickRow}
        hasError={listQuery.hasError}
        isLoading={listQuery.isLoading}
        data={allItems}
        columns={_columns}
        sortBy={sort?.sortBy as string}
        sortDirection={sort?.sortDirection}
        onSortChange={(newSortBy, newSortDirection) => {
          setSort({
            sortBy: newSortBy as keyof ValueType,
            sortDirection: newSortDirection as SortDirectionType
          });
        }}
      />
      <DataTableLoadMoreFooter
        isFetchingNextPage={listQuery.isFetchingNextPage}
        fetchNextPage={listQuery.fetchNextPage}
        hasNextPage={listQuery.hasNextPage}
      />
    </ToolbarContentPage>
  );
};

export default CRUDView;
