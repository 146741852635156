import checkStatus from './checkStatus';
import { getApiEnvironment } from './apiEnvironment';
import { FetchOptions } from 'ecto-common/lib/utils/APIFetchType';
import _ from 'lodash';

const APIFetch = ({
  path,
  domain = getApiEnvironment().urls.url,
  options = {},
  shouldCheckStatus = true
}: FetchOptions) => {
  const url = (_.isFunction(domain) ? domain() : domain) + path;

  return fetch(url, options).then((response) => {
    if (shouldCheckStatus) {
      return checkStatus(response);
    }

    return response;
  });
};

export default APIFetch;
