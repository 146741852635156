import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import styles from './SelectedNodeDialog.module.css';
import { EditIcon } from 'ecto-common/lib/Icon';
import T from 'ecto-common/lib/lang/Language';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import SelectEquipment from 'ecto-common/lib/SelectEquipment/SelectEquipment';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';

interface SelectNodeDialogProps {
  isOpen: boolean;
  onModalClose: () => void;
  onNodesSelected: (nodeIds: string[]) => void;
  nodeIds: string[];
  multiSelect: boolean;
  filterNodes?: (nodes: NodeV2ResponseModel[]) => NodeV2ResponseModel[];
}

const SelectNodeDialog = ({
  isOpen,
  onModalClose,
  onNodesSelected,
  nodeIds,
  multiSelect = true,
  filterNodes = null
}: SelectNodeDialogProps) => {
  const [tempNodeIds, setTempNodeIds] = useState<string[]>(nodeIds);

  useEffect(() => {
    setTempNodeIds(nodeIds);
  }, [nodeIds]);

  const selectDone = useCallback(() => {
    onNodesSelected(tempNodeIds);
    onModalClose();
  }, [onModalClose, onNodesSelected, tempNodeIds]);

  return (
    <Modal
      className={styles.editParentsModal}
      onModalClose={onModalClose}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={EditIcon}>
        {T.admin.selectlocationdialog.title}
      </ModalHeader>
      <ModalBody
        className={styles.editParentBody}
        withoutPadding
        withWhiteBackground
      >
        <SelectEquipment
          embedInDialog
          multiSelect={multiSelect}
          selectedIds={tempNodeIds}
          setSelectedIds={setTempNodeIds}
          key={isOpen + ''}
          filterNodes={filterNodes}
        />
      </ModalBody>
      <ModalFooter>
        <LocalizedButtons.Ok onClick={selectDone} />
        <LocalizedButtons.Cancel onClick={onModalClose} />
      </ModalFooter>
    </Modal>
  );
};

export default SelectNodeDialog;
