/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type ListResponseWithContinuationTokenOfAlarmRuleModel =
  ListResponseOfAlarmRuleModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfAlarmRuleModel {
  items?: AlarmRuleModel[];
}

export interface AlarmRuleModel {
  id?: string | null;
  dataPointId?: string | null;
  name?: string | null;
  description?: string | null;
  /** @format int32 */
  severity?: number;
  /** @format float */
  thresholdValue?: number;
  thresholdOperator?: ComparisonOperatorEnum;
  /** @format int32 */
  activationDelay?: number;
  /** @format float */
  resetValue?: number;
  resetOperator?: ComparisonOperatorEnum;
}

export enum ComparisonOperatorEnum {
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  Equal = 'equal',
  NotEqual = 'notEqual',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual'
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export type ListResponseWithContinuationTokenOfAlarmModel =
  ListResponseOfAlarmModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfAlarmModel {
  items?: AlarmModel[];
}

export interface AlarmModel {
  sourceUri?: string | null;
  /** @format int32 */
  severity?: number;
  isActive?: boolean;
  canAcknowledge?: boolean;
  /** @format date-time */
  acknowledgedAt?: string | null;
  acknowledgedBy?: string | null;
  acknowledgedComment?: string | null;
  /**
   * Gets how many times the alarm has been active since it was acknowledged the last time.
   * The first active state doesn't count i.e. ACTIVE -> INACTIVE -> ACTIVE = 1.
   * @format int32
   */
  countActiveStatesSinceLastAcknowledgment?: number;
  /**
   * Gets the date when the alarm first became active after it was previously acknowledged or
   * never active.
   * @format date-time
   */
  firstActiveAtSinceLastAcknowledgement?: string | null;
  nodeId?: string | null;
  nodeName?: string | null;
  nodePath?: NodePathItem[] | null;
  properties?: Record<string, string>;
  message?: string | null;
  contexts?: AlarmContextModel[] | null;
}

export interface NodePathItem {
  id?: string | null;
  name?: string | null;
  type?: string | null;
}

export interface AlarmContextModel {
  id?: string | null;
  name?: string | null;
  properties?: Record<string, AlarmPropertyModel>;
}

export interface AlarmPropertyModel {
  value?: string | null;
  description?: string | null;
}

export type ListResponseWithContinuationTokenOfAlarmEventModel =
  ListResponseOfAlarmEventModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfAlarmEventModel {
  items?: AlarmEventModel[];
}

export interface AlarmEventModel {
  sourceUri?: string | null;
  /** @format int32 */
  severity?: number;
  isActive?: boolean;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  acknowledgedAt?: string | null;
  acknowledgedBy?: string | null;
  acknowledgedComment?: string | null;
  /**
   * Gets the date when the alarm first became active after it was previously acknowledged or
   * never active.
   * @format date-time
   */
  firstActiveAtSinceLastAcknowledgement?: string | null;
  nodeId?: string | null;
  nodeName?: string | null;
  nodePath?: NodePathItem[] | null;
  properties?: Record<string, string>;
  message?: string | null;
  contexts?: AlarmContextModel[] | null;
}

export interface AcknowledgeAlarmRequest {
  /** @minLength 1 */
  sourceUri: string;
  comment?: string | null;
}

export interface AcknowledgeAlarmsRequest {
  /** @minLength 1 */
  nodeId: string;
  filter?: string | null;
  comment?: string | null;
}

export interface AlarmRulesListAlarmRulesParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface AlarmsListAlarmsParams {
  /** The token that specifies where to continue fetching results. */
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface AlarmsListAlarmEventsParams {
  /** The page token to use when continuing the page enumeration. */
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface VersionVersionParams {
  'api-version'?: string | null;
}

/**
 * @title Alarms API
 * @version v1
 * @baseUrl https://app-ec-alarm-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  apiEndpoint,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  ContentType,
  Method
} from './APIUtils';

const apiTitle = APIGenType.AlarmsAPIGen;

export interface AlarmRulesDeleteAlarmRulePath {
  id: string;
}

export interface AlarmsAcknowledgeAlarmPath {
  acknowledge: string;
}

export interface AlarmsAcknowledgeAlarmsPath {
  batchAcknowledge: string;
}

const AlarmsAPIGen = {
  AlarmRules: {
    /**
     * @summary Lists alarm rules for tenant based on odata query
     */
    listAlarmRules: apiEndpointEmptyUsingQueryParams<
      AlarmRulesListAlarmRulesParams,
      ListResponseWithContinuationTokenOfAlarmRuleModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/alarmrules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Creates an alarm rule
     */
    createAlarmRule: apiEndpoint<
      AlarmRuleModel,
      AlarmRuleModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/alarmrules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Updates an alarm rule
     */
    updateAlarmRule: apiEndpoint<
      AlarmRuleModel,
      AlarmRuleModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/v1/alarmrules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes alarm rule with id
     */
    deleteAlarmRule: apiEndpointEmptyWithPath<
      void,
      string | ProblemDetails,
      AlarmRulesDeleteAlarmRulePath
    >(
      Method.DELETE,
      ({ id }: AlarmRulesDeleteAlarmRulePath) => `/api/v1/alarmrules/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Alarms: {
    /**
     * @summary Gets all the alarms for the supplied node in a paged manner.
     */
    listAlarms: apiEndpointEmptyUsingQueryParams<
      AlarmsListAlarmsParams,
      ListResponseWithContinuationTokenOfAlarmModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/alarms`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all the alarm events for the supplied node in a paged manner.
     */
    listAlarmEvents: apiEndpointEmptyUsingQueryParams<
      AlarmsListAlarmEventsParams,
      ListResponseWithContinuationTokenOfAlarmEventModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/alarms/events`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Acknowledges an alarm.
     */
    acknowledgeAlarm: apiEndpoint<
      AcknowledgeAlarmRequest,
      void,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/alarms:acknowledge`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
 * @summary Acknowledges the alarms that has the supplied node in the node path. If a filter is supplied then only the
alarms matching that filter will be acknowledged.
*/
    acknowledgeAlarms: apiEndpoint<AcknowledgeAlarmsRequest, void, any>(
      Method.POST,
      `/api/v1/alarms:batchAcknowledge`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmptyUsingQueryParams<VersionVersionParams, File, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default AlarmsAPIGen;
