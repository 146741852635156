import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import Button from 'ecto-common/lib/Button/Button';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import { HelpIcon } from '../../Icon';
import styles from './ActionModal.module.css';
import T from '../../lang/Language';
import ModalSpace from '../ModalSpace';
import SearchInput from 'ecto-common/lib/SearchInput/SearchInput';
import ModalHeaderHelpRightContent from 'ecto-common/lib/Modal/ModalHeaderHelpRightContent';
import { ModalTitleIconProps } from '../ModalHeader';

export interface ActionModalProps {
  /**
   * Called whenever the modal closes.
   */
  onModalClose(): void;
  /**
   * If set to true the Modal will be slightly smaller.
   */
  compact?: boolean;
  /**
   * If set to true the Modal will be slightly larger (both width and height).
   */
  large?: boolean;
  /**
   * If set to true the Modal will be wider, but the height will still be automatically calculated.
   */
  wide?: boolean;
  /**
   * If set to true the Modal will cover almost the entire screen (will leave a small margin)
   */
  fullScreen?: boolean;
  /**
   * Whether the dialog is open. Use this instead of { isOpen && <Modal ...> } to get correct animations.
   */
  isOpen?: boolean;
  /**
   * The content of the Modal. Unlike the regular Modal you should not add ModalHeader, ModalBody and ModalFooter components here. Instead, just add whatever would have gone into the ModalBody.
   */
  children?: React.ReactNode;
  /**
   * The title of the Modal
   */
  title: React.ReactNode;
  /**
   * Called whenever the confirm button has been pressed.
   */
  onConfirmClick(): void;
  /**
   * The title for the confirm button.
   */
  actionText?: React.ReactNode;
  /**
   * The title for the cancel button.
   */
  cancelText?: string;
  /**
   * Whether the component is in a loading state or not. If loading it will show a spinner and the buttons will be disabled.
   */
  isLoading?: boolean;
  /**
   * Custom text to be shown while loading.
   */
  loadingText?: string;
  /**
   * The icon to be used in the header component.
   */
  headerIcon?: React.FC<ModalTitleIconProps>;

  /**
   * If set to true the confirm button is disabled.
   */
  disableActionButton?: boolean;
  /**
   * Used to override the appearance of the modal. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Used to override the appearance of the modal body. Should be a valid CSS class name.
   */
  messageBodyClassName?: string;
  /**
   * If set to true then the cancel button is not shown.
   */
  disableCancel?: boolean;
  /**
   * If you wish to supply a button that will appear to the left in the footer (like a delete button), set this property
   */
  leftSideButton?: React.ReactNode;
  /**
   * If you want to supply a custom footer for the ActionModal you should use this prop.
   */
  footer?: React.ReactNode;
  /**
   * If set to true, show a search field at the top of the action modal. Use onSearchTextChanged
   * and searchText to control component
   */
  withSearchField?: boolean;
  /**
   * Use this to add more buttons next to the search field.
   */
  searchFieldButtons?: React.ReactNode;
  /**
   * Called whenever the search field content changes
   */
  onSearchTextChanged?(searchText: string): void;
  /**
   * The search text to use for the search field
   */
  searchText?: string;
  /**
   * String that points out the path to a help file. This property inserts a help tooltip button in the modal header
   */
  helpPath?: string;

  /**
   * If shown via a dropdown menu, set this attribute to prevent the dropdown menu
   * to close when clicking inside the modal.
   */
  preventCloseDropdownMenu?: boolean;

  /**
   * If set to true, the modal content will not be scrollable. This is useful when you want
   * to support components like the date picker, that should overflow outside the bounds of
   * the modal. If scrolling is enabled, it will just scroll within the modal body.
   */
  enablePopoutChildren?: boolean;
}

/**
 * Convenience Modal wrapper that can be used when creating confirm or edit dialogs. Creates the inner Modal components (ModalHeader, ModalBody, ModalFooter) so that it requires less code to get a working dialog.
 *
 * It also has a compact/large sizing scheme.
 */
const ActionModal = ({
  className = null,
  large = false,
  wide = false,
  fullScreen = false,
  title,
  children,
  disableActionButton = false,
  headerIcon = HelpIcon,
  onModalClose,
  isOpen = false,
  onConfirmClick,
  actionText = T.common.ok,
  isLoading = false,
  loadingText = null,
  messageBodyClassName = null,
  cancelText = null,
  disableCancel = false,
  compact = false,
  footer = null,
  leftSideButton = null,
  withSearchField = false,
  searchFieldButtons = null,
  onSearchTextChanged = null,
  searchText = null,
  helpPath = null,
  preventCloseDropdownMenu = false,
  enablePopoutChildren = false
}: ActionModalProps) => {
  const [positionStyle, setPositionStyle] = useState({ left: 0, top: 0 });

  const [confirmButtonRef, setConfirmButtonRef] =
    useState<HTMLButtonElement>(null);
  const [cancelButtonRef, setCancelButtonRef] =
    useState<HTMLButtonElement>(null);
  const searchContainerRef = useRef(null);
  const bodyRef = useRef(null);

  useLayoutEffect(() => {
    if (isOpen && !isLoading) {
      const activeElement = document.activeElement;

      // Check if the active element is an input, textarea, or other interactive element
      const isInputField =
        activeElement &&
        activeElement instanceof HTMLElement &&
        (activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA' ||
          activeElement.isContentEditable);

      if (!isInputField) {
        if (confirmButtonRef && !disableActionButton) {
          confirmButtonRef.focus();
        } else if (cancelButtonRef && !disableCancel) {
          cancelButtonRef.focus();
        }
      }
    }
  }, [
    cancelButtonRef,
    confirmButtonRef,
    disableActionButton,
    disableCancel,
    isLoading,
    isOpen
  ]);

  useEffect(() => {
    if (!isOpen) {
      setPositionStyle({ left: 0, top: 0 });
    }
  }, [isOpen]);

  const onScroll: React.UIEventHandler<HTMLDivElement> = useCallback((e) => {
    if (searchContainerRef.current) {
      if ((e.target as HTMLElement).scrollTop > 0) {
        searchContainerRef.current.className = classNames(
          styles.searchContainer,
          styles.searchContainerShadow
        );
      } else {
        searchContainerRef.current.className = styles.searchContainer;
      }
    }
  }, []);

  const _onSearchTextChanged = useCallback(
    (text: string) => {
      onSearchTextChanged(text ?? '');
      if (bodyRef.current) {
        bodyRef.current.scrollTop = 0;
      }
    },
    [onSearchTextChanged]
  );

  const dragHandler = useCallback((deltaX: number, deltaY: number) => {
    setPositionStyle((oldPositionStyle) => ({
      left: oldPositionStyle.left + deltaX,
      top: oldPositionStyle.top + deltaY
    }));
  }, []);

  return (
    <Modal
      onModalClose={onModalClose}
      isOpen={isOpen}
      className={classNames(
        !large && styles.modal,
        wide && styles.wide,
        compact && styles.compact,
        fullScreen && styles.fullScreen,
        enablePopoutChildren && styles.showOverflow,
        className
      )}
      disableClose={isLoading}
      preventCloseDropdownMenu={preventCloseDropdownMenu}
      style={positionStyle}
    >
      <ModalHeader
        titleIcon={headerIcon}
        rightContent={<ModalHeaderHelpRightContent helpPath={helpPath} />}
        onHeaderDragged={dragHandler}
      >
        {title}
      </ModalHeader>

      <ModalBody
        loading={isLoading}
        loadingText={loadingText}
        className={classNames(
          styles.messageBody,
          messageBodyClassName,
          withSearchField && styles.searchFieldBody,
          enablePopoutChildren && styles.disableScroll
        )}
        onScroll={onScroll}
        ref={bodyRef}
      >
        {withSearchField && (
          <div className={styles.searchContainer} ref={searchContainerRef}>
            <SearchInput
              onChange={_onSearchTextChanged}
              initialValue={searchText}
              wrapperClassName={styles.searchField}
            />
            {searchFieldButtons}
          </div>
        )}
        {children}
      </ModalBody>
      {footer || (
        <ModalFooter>
          {leftSideButton && (
            <Fragment>
              {leftSideButton}
              <ModalSpace />
            </Fragment>
          )}
          {onConfirmClick && (
            <Button
              onClick={onConfirmClick}
              disabled={isLoading || disableActionButton}
              ref={setConfirmButtonRef}
              tabIndex={-1}
            >
              {actionText}
            </Button>
          )}

          {!disableCancel && (
            <GreyButton
              onClick={onModalClose}
              disabled={isLoading}
              ref={setCancelButtonRef}
              className={styles.button}
              tabIndex={-1}
            >
              {cancelText || T.common.actionmodal.cancel}
            </GreyButton>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default React.memo(ActionModal);
