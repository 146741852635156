import React from 'react';
import {
  TemplateManagementRoute,
  LocationRoute,
  ProvisioningRoute,
  BuildingsRoute,
  HelpRoute,
  GlobalTenantAdminRoute,
  TenantUsersAdminRoute,
  NotificationsRoute,
  IntegrationAccountsRoute,
  IntegrationsRoute,
  EctotableRoute,
  MeteorologyRoute,
  ProcessMapsManagementRoute,
  NotificationsV2Route,
  AlarmRulesRoute,
  DashboardsManagementRoute,
  NodeTraitsRoute,
  NodePropertiesRoute,
  GlobalNotificationsRoute
} from '../utils/routeConstants';
import { RouteItem } from 'ecto-common/lib/BaseContainer/BaseContainer';
import TemplateManagement from 'js/components/TemplateManagement/TemplateManagement';
import NotificationsPage from 'js/components/Notifications/NotificationsPage';
import Buildings from 'js/components/Buildings/Buildings';
import Location from 'js/components/Location/Location';
import EnergyManagers from 'js/components/EnergyManagers/EnergyManagers';
import ManageUsersPage from 'js/components/ManageUsers/ManageUsersPage';
import ManageTenants from 'js/components/ManageTenants/ManageTenants';
import Help from 'js/components/Help/Help';
import IntegrationAccounts from 'js/components/IntegrationAccounts/IntegrationAccounts';
import AdminPage from 'js/components/AdminPage';
import EctotableRoomList from 'js/components/Ectotable/ManageEctotablePage';
import IntegrationsPage from 'js/components/Integrations/IntegrationsPage';
import MeteorologyPage from 'js/components/Meteorology/MeteorologyPage';
import ProcessMapsPage from 'js/components/ProcessMaps/ProcessMapsPage';
import AlarmRulesPage from 'js/components/Alarms/AlarmRulesPage';
import NotificationsV2Page from 'js/components/Notifications/NotificationsV2Page';
import TenantDashboardsPage from 'js/components/Dashboards/TenantDashboardsPage';
import NodeTraits from 'js/components/NodeTraits/NodeTraits';
import NodeProperties from 'js/components/NodeProperties/NodeProperties';
import GlobalNotificationsPage from 'js/components/Notifications/GlobalNotificationsPage';

const RouteAdminPage = (PageComponent: React.FC) => () => (
  <AdminPage content={<PageComponent />} />
);

const WrappedIntegrationsPage = RouteAdminPage(IntegrationsPage);
const WrappedEctotableRoomList = RouteAdminPage(EctotableRoomList);
const WrappedMeteorologyPage = RouteAdminPage(MeteorologyPage);
const WrappedIntegrationAccountsPage = RouteAdminPage(IntegrationAccounts);
const WrappedTenantDashboardsPage = RouteAdminPage(TenantDashboardsPage);
const WrappedProcessMapsPage = RouteAdminPage(ProcessMapsPage);
//
// All page routes. Keep these ordered in order of importance, i.e. which one would you like to see first
// if you do not have access to the others in front of it.
export const adminRoutes: RouteItem[] = [
  { ...LocationRoute, element: <Location /> },
  { ...NotificationsV2Route, element: <NotificationsV2Page /> },
  { ...GlobalNotificationsRoute, element: <GlobalNotificationsPage /> },
  { ...NotificationsRoute, element: <NotificationsPage /> },
  { ...AlarmRulesRoute, element: <AlarmRulesPage /> },
  { ...IntegrationsRoute, element: <WrappedIntegrationsPage /> },
  { ...EctotableRoute, element: <WrappedEctotableRoomList /> },
  { ...MeteorologyRoute, element: <WrappedMeteorologyPage /> },
  { ...TemplateManagementRoute, element: <TemplateManagement /> },
  { ...BuildingsRoute, element: <Buildings /> },
  { ...ProvisioningRoute, element: <EnergyManagers /> },
  { ...NodeTraitsRoute, element: <NodeTraits /> },
  { ...NodePropertiesRoute, element: <NodeProperties /> },
  {
    ...IntegrationAccountsRoute,
    element: <WrappedIntegrationAccountsPage />
  },
  {
    ...DashboardsManagementRoute,
    element: <WrappedTenantDashboardsPage />
  },
  {
    ...ProcessMapsManagementRoute,
    element: <WrappedProcessMapsPage />
  },
  { ...TenantUsersAdminRoute, element: <ManageUsersPage /> },
  { ...GlobalTenantAdminRoute, element: <ManageTenants /> },
  { ...HelpRoute, element: <Help /> }
];
