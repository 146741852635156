import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  getSignalProvidersPage,
  SignalProvidersPageValue
} from '../utils/commonLinkUtil';
import React, { useCallback, useContext } from 'react';
import TenantContext from '../hooks/TenantContext';
import _ from 'lodash';
import APIGen from '../API/APIGen';
import { ProcessMapDropdownShowMenuCallback } from './useProcessMapDropdown';
import sortByLocaleCompare from '../utils/sortByLocaleCompare';
import { beautifyEquipmentName } from '../utils/equipmentTypeUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useNodeChildren } from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';

const useEquipmentLinkAction = ({
  nodeId,
  showMenu,
  hideMenu
}: {
  nodeId: string;
  showMenu: ProcessMapDropdownShowMenuCallback;
  hideMenu: () => void;
}) => {
  const navigate = useNavigate();
  const params = useParams<NodeParams>();

  const { contextSettings } = useContext(TenantContext);
  const { nodeChildren } = useNodeChildren([nodeId]);
  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();
  const [searchParams] = useSearchParams();

  return useCallback(
    (event: MouseEvent, signalTraitId: string) => {
      if (nodeId == null) {
        return;
      }

      const equipments = _.filter(nodeChildren, (x) =>
        x.nodeTraits.some((trait) => trait.nodeTraitId === signalTraitId)
      );

      if (equipments.length === 0) {
        return;
      }

      const sortedEquipment = sortByLocaleCompare(equipments, 'name');
      showMenu(
        sortedEquipment.map((equipment) => ({
          label: equipment.name,
          disableCloseOnClick: true,
          subtitle: beautifyEquipmentName(
            traitsQuery.data?.items?.find((x) => x.id === signalTraitId)?.name
          ),
          icon: <Icons.ArrowRight />,
          action: () => {
            hideMenu();
            navigate(
              getSignalProvidersPage(
                contextSettings.tenantId,
                nodeId,
                // Maintain search params if we're navigating internally to another signal providers page
                params.page === SignalProvidersPageValue ? searchParams : null
              )
            );
          }
        })),
        {
          x: event.clientX,
          y: event.clientY
        }
      );
    },
    [
      nodeId,
      nodeChildren,
      showMenu,
      traitsQuery.data,
      hideMenu,
      navigate,
      contextSettings.tenantId,
      params.page,
      searchParams
    ]
  );
};

export default useEquipmentLinkAction;
