/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ListResponseOfInformationModel {
  items?: InformationModel[];
}

export interface InformationModel {
  id?: string | null;
  message?: string | null;
  tenants?: string[] | null;
}

export interface InformationRequest {
  message?: string | null;
  tenants?: string[] | null;
}

export interface PictureModel {
  id?: string | null;
  tenant?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  allowedNodeTypes?: string[] | null;
}

export interface ListResponseOfAssignedPictureModel {
  items?: AssignedPictureModel[];
}

export interface AssignedPictureModel {
  id?: string | null;
  /** @format int32 */
  priority?: number;
  nodeId?: string | null;
  name?: string | null;
  data?: string | null;
  type?: AssignmentTypeEnum;
}

export enum AssignmentTypeEnum {
  Global = 'global',
  Tenant = 'tenant'
}

export interface ListResponseOfString {
  items?: string[];
}

export interface ListResponseOfAssignedTenantDashboardModel {
  items?: AssignedTenantDashboardModel[];
}

export interface AssignedTenantDashboardModel {
  id?: string | null;
  /** @format int32 */
  priority?: number;
  nodeId?: string | null;
  name?: string | null;
  data?: string | null;
  type?: AssignmentTypeEnum;
}

export interface ListResponseOfFileMetadataModel {
  items?: FileMetadataModel[];
}

export interface FileMetadataModel {
  id?: string | null;
  name?: string | null;
  /** @format int64 */
  size?: number;
  /** @format date-time */
  lastModified?: string | null;
}

export interface DownloadFileResponse {
  downloadUrl?: string | null;
}

export interface AssignPictureToNodeRequest {
  id?: string | null;
  /** @format int32 */
  priority?: number;
}

export interface AssignTenantDashboardToNodeRequest {
  id?: string | null;
  /** @format int32 */
  priority?: number;
}

export interface IHeaderDictionary {
  Item?: any[];
  /** @format int64 */
  ContentLength?: number | null;
  Accept?: any[];
  AcceptCharset?: any[];
  AcceptEncoding?: any[];
  AcceptLanguage?: any[];
  AcceptRanges?: any[];
  AccessControlAllowCredentials?: any[];
  AccessControlAllowHeaders?: any[];
  AccessControlAllowMethods?: any[];
  AccessControlAllowOrigin?: any[];
  AccessControlExposeHeaders?: any[];
  AccessControlMaxAge?: any[];
  AccessControlRequestHeaders?: any[];
  AccessControlRequestMethod?: any[];
  Age?: any[];
  Allow?: any[];
  AltSvc?: any[];
  Authorization?: any[];
  Baggage?: any[];
  CacheControl?: any[];
  Connection?: any[];
  ContentDisposition?: any[];
  ContentEncoding?: any[];
  ContentLanguage?: any[];
  ContentLocation?: any[];
  ContentMD5?: any[];
  ContentRange?: any[];
  ContentSecurityPolicy?: any[];
  ContentSecurityPolicyReportOnly?: any[];
  ContentType?: any[];
  CorrelationContext?: any[];
  Cookie?: any[];
  Date?: any[];
  ETag?: any[];
  Expires?: any[];
  Expect?: any[];
  From?: any[];
  GrpcAcceptEncoding?: any[];
  GrpcEncoding?: any[];
  GrpcMessage?: any[];
  GrpcStatus?: any[];
  GrpcTimeout?: any[];
  Host?: any[];
  KeepAlive?: any[];
  IfMatch?: any[];
  IfModifiedSince?: any[];
  IfNoneMatch?: any[];
  IfRange?: any[];
  IfUnmodifiedSince?: any[];
  LastModified?: any[];
  Link?: any[];
  Location?: any[];
  MaxForwards?: any[];
  Origin?: any[];
  Pragma?: any[];
  ProxyAuthenticate?: any[];
  ProxyAuthorization?: any[];
  ProxyConnection?: any[];
  Range?: any[];
  Referer?: any[];
  RetryAfter?: any[];
  RequestId?: any[];
  SecWebSocketAccept?: any[];
  SecWebSocketKey?: any[];
  SecWebSocketProtocol?: any[];
  SecWebSocketVersion?: any[];
  SecWebSocketExtensions?: any[];
  Server?: any[];
  SetCookie?: any[];
  StrictTransportSecurity?: any[];
  TE?: any[];
  Trailer?: any[];
  TransferEncoding?: any[];
  Translate?: any[];
  TraceParent?: any[];
  TraceState?: any[];
  Upgrade?: any[];
  UpgradeInsecureRequests?: any[];
  UserAgent?: any[];
  Vary?: any[];
  Via?: any[];
  Warning?: any[];
  WebSocketSubProtocols?: any[];
  WWWAuthenticate?: any[];
  XContentTypeOptions?: any[];
  XFrameOptions?: any[];
  XPoweredBy?: any[];
  XRequestedWith?: any[];
  XUACompatible?: any[];
  XXSSProtection?: any[];
}

export type ListResponseWithContinuationTokenOfPictureModel =
  ListResponseOfPictureModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfPictureModel {
  items?: PictureModel[];
}

export type ListResponseWithContinuationTokenOfSymbolModel =
  ListResponseOfSymbolModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfSymbolModel {
  items?: SymbolModel[];
}

export interface SymbolModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  states?: string[] | null;
  connections?: ConnectionModel[] | null;
}

export interface ConnectionModel {
  id?: string | null;
  /** @format float */
  x?: number;
  /** @format float */
  y?: number;
}

export type ListResponseWithContinuationTokenOfTenantDashboardModel =
  ListResponseOfTenantDashboardModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfTenantDashboardModel {
  items?: TenantDashboardModel[];
}

export interface TenantDashboardModel {
  id?: string | null;
  tenant?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  allowedNodeTypes?: string[] | null;
}

export interface DatapointDeleteDatapointSamplesParams {
  /**
   * Inclusive start time in the interval
   * @format date-time
   */
  startTime?: string;
  /**
   * Exclusive end time in the interval
   * @format date-time
   */
  endTime?: string;
  /** Id of the datapoint to delete samples for. */
  id: string;
}

export interface NodesGetPicturesParams {
  /** Ids for nodes and node types */
  nodeIds?: string[] | null;
}

export interface NodesGetDashboardsParams {
  /** Ids for nodes and node types */
  nodeIds?: string[] | null;
}

export interface NodesGetFilesParams {
  /** Prefix of file id used for search */
  prefix?: string | null;
  /** Node with files */
  nodeId: string;
}

export interface NodesGetFileParams {
  openFile?: boolean;
  /** Node to get file from */
  nodeId: string;
  /** Id of file */
  id: string;
}

export interface PicturesListPicturesParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface SymbolsListSymbolsParams {
  /** The token that specifies where to continue fetching results. */
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface TenantDashboardsListTenantDashboardsParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface VersionVersionParams {
  'api-version'?: string | null;
}

/**
 * @title Presentation API
 * @version v1
 * @baseUrl https://app-ec-presentation-dev-weu-001-7xkz.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  apiEndpoint,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointWithPath,
  ContentType,
  Method
} from './APIUtils';

const apiTitle = APIGenType.PresentationAPIGen;

export interface DatapointDeleteDatapointSamplesPath {
  /** Id of the datapoint to delete samples for. */
  id: string;
}

export interface DatapointDeleteDatapointWithSamplesPath {
  /** Id of the datapoint to delete samples for. */
  id: string;
}

export interface InformationDeleteInformationPath {
  /** Id of the information message to delete */
  id: string;
}

export interface InformationUpdateInformationPath {
  id: string;
}

export interface NodesGetPictureNodeAssignmentsPath {
  id: string;
}

export interface NodesGetDashboardNodeAssignmentsPath {
  id: string;
}

export interface NodesGetFilesPath {
  /** Node with files */
  nodeId: string;
}

export interface NodesAddFilePath {
  /** Node where file is added */
  nodeId: string;
}

export interface NodesGetFilePath {
  /** Node to get file from */
  nodeId: string;
  /** Id of file */
  id: string;
}

export interface NodesDeleteFilePath {
  /** Node to delete file from */
  nodeId: string;
  /** Id of file */
  id: string;
}

export interface NodesAssignPicturesPath {
  /** Id of node or node type to assign to */
  nodeId: string;
}

export interface NodesAssignTenantDashboardsPath {
  /** Id of node or node type to assign to */
  nodeId: string;
}

export interface NodesUnassignPicturePath {
  /** Id of node or node type */
  nodeId: string;
  /** Id of picture to unassign */
  id: string;
}

export interface NodesUnassignDashboardPath {
  /** Id of node or node type */
  nodeId: string;
  id: string;
}

export interface PicturesGetPicturePath {
  id: string;
}

export interface PicturesUpdatePicturePath {
  id: string;
}

export interface PicturesDeletePicturePath {
  id: string;
}

export interface SymbolsGetSymbolPath {
  /** The symbol id to get. */
  id: string;
}

export interface SymbolsUpdateSymbolPath {
  /** The symbol id. */
  id: string;
}

export interface SymbolsDeleteSymbolPath {
  /** Id of the symbol to delete. */
  id: string;
}

export interface TenantDashboardsGetTenantDashboardPath {
  /** The dashboard id to get. */
  id: string;
}

export interface TenantDashboardsUpdateTenantDashboardPath {
  /** The tenant dashboard id. */
  id: string;
}

export interface TenantDashboardsDeleteTenantDashboardPath {
  /** Id of the dashboard to delete */
  id: string;
}

const PresentationAPIGen = {
  Datapoint: {
    /**
     * @summary Deletes data samples for a datapoint.
     */
    deleteDatapointSamples: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        DatapointDeleteDatapointSamplesParams,
        keyof DatapointDeleteDatapointSamplesPath
      >,
      void,
      ProblemDetails,
      DatapointDeleteDatapointSamplesPath
    >(
      Method.DELETE,
      ({ id }: DatapointDeleteDatapointSamplesPath) =>
        `/api/v1/datapoints/${id}/samples`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes data samples for a datapoint for a given interval.
     */
    deleteDatapointWithSamples: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      DatapointDeleteDatapointWithSamplesPath
    >(
      Method.DELETE,
      ({ id }: DatapointDeleteDatapointWithSamplesPath) =>
        `/api/v1/datapoints/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Information: {
    /**
     * @summary Gets system information.
     */
    getCurrentTenantInformation: apiEndpointEmpty<
      ListResponseOfInformationModel,
      any
    >(
      Method.GET,
      `/api/v1/information`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Create system information messages.
     */
    createInformation: apiEndpoint<
      InformationRequest,
      InformationModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/information`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets system information.
     */
    getAllInformation: apiEndpointEmpty<ListResponseOfInformationModel, any>(
      Method.GET,
      `/api/v1/information/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes a system information message.
     */
    deleteInformation: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      InformationDeleteInformationPath
    >(
      Method.DELETE,
      ({ id }: InformationDeleteInformationPath) => `/api/v1/information/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    updateInformation: apiEndpointWithPath<
      InformationRequest,
      PictureModel,
      ProblemDetails,
      InformationUpdateInformationPath
    >(
      Method.PUT,
      ({ id }: InformationUpdateInformationPath) => `/api/v1/information/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Nodes: {
    /**
     * @summary Gets all pictures assigned to nodes or node types with given ids
     */
    getPictures: apiEndpointEmptyUsingQueryParams<
      NodesGetPicturesParams,
      ListResponseOfAssignedPictureModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/nodes/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all node ids where the picture is assigned
     */
    getPictureNodeAssignments: apiEndpointEmptyWithPath<
      ListResponseOfString,
      ProblemDetails,
      NodesGetPictureNodeAssignmentsPath
    >(
      Method.GET,
      ({ id }: NodesGetPictureNodeAssignmentsPath) =>
        `/api/v1/nodes/pictures/${id}/assignments`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all tenant dashboards assigned to nodes or node types with given ids
     */
    getDashboards: apiEndpointEmptyUsingQueryParams<
      NodesGetDashboardsParams,
      ListResponseOfAssignedTenantDashboardModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/nodes/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all node ids where the dashboard is assigned
     */
    getDashboardNodeAssignments: apiEndpointEmptyWithPath<
      ListResponseOfString,
      ProblemDetails,
      NodesGetDashboardNodeAssignmentsPath
    >(
      Method.GET,
      ({ id }: NodesGetDashboardNodeAssignmentsPath) =>
        `/api/v1/nodes/dashboards/${id}/assignments`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets files for node
     */
    getFiles: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<NodesGetFilesParams, keyof NodesGetFilesPath>,
      ListResponseOfFileMetadataModel,
      ProblemDetails,
      NodesGetFilesPath
    >(
      Method.GET,
      ({ nodeId }: NodesGetFilesPath) => `/api/v1/nodes/${nodeId}/files`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Add a file to node
     */
    addFile: apiEndpointWithPath<
      {
        /** @format binary */
        file?: File;
      },
      FileMetadataModel,
      ProblemDetails,
      NodesAddFilePath
    >(
      Method.POST,
      ({ nodeId }: NodesAddFilePath) => `/api/v1/nodes/${nodeId}/files`,
      apiTitle,
      ContentType.FormData,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Get url where file can be downloaded
     */
    getFile: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<NodesGetFileParams, keyof NodesGetFilePath>,
      DownloadFileResponse,
      ProblemDetails,
      NodesGetFilePath
    >(
      Method.GET,
      ({ nodeId, id }: NodesGetFilePath) =>
        `/api/v1/nodes/${nodeId}/files/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes a file
     */
    deleteFile: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NodesDeleteFilePath
    >(
      Method.DELETE,
      ({ nodeId, id }: NodesDeleteFilePath) =>
        `/api/v1/nodes/${nodeId}/files/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Assign pictures to node or node type with given id
     */
    assignPictures: apiEndpointWithPath<
      AssignPictureToNodeRequest[],
      AssignedPictureModel[],
      ProblemDetails,
      NodesAssignPicturesPath
    >(
      Method.PUT,
      ({ nodeId }: NodesAssignPicturesPath) =>
        `/api/v1/nodes/${nodeId}/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Assign tenant dashboards to node or node type with given id
     */
    assignTenantDashboards: apiEndpointWithPath<
      AssignTenantDashboardToNodeRequest[],
      AssignedTenantDashboardModel[],
      ProblemDetails,
      NodesAssignTenantDashboardsPath
    >(
      Method.PUT,
      ({ nodeId }: NodesAssignTenantDashboardsPath) =>
        `/api/v1/nodes/${nodeId}/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Unassign picture from node or node type
     */
    unassignPicture: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NodesUnassignPicturePath
    >(
      Method.DELETE,
      ({ nodeId, id }: NodesUnassignPicturePath) =>
        `/api/v1/nodes/${nodeId}/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Unassign tenant dashboard from node or node type
     */
    unassignDashboard: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NodesUnassignDashboardPath
    >(
      Method.DELETE,
      ({ nodeId, id }: NodesUnassignDashboardPath) =>
        `/api/v1/nodes/${nodeId}/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Pictures: {
    /**
     * @summary Returns list of all pictures in tenant
     */
    listPictures: apiEndpointEmptyUsingQueryParams<
      PicturesListPicturesParams,
      ListResponseWithContinuationTokenOfPictureModel,
      any
    >(
      Method.GET,
      `/api/v1/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Creates a new picture
     */
    createPicture: apiEndpoint<PictureModel, PictureModel, ProblemDetails>(
      Method.POST,
      `/api/v1/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns a single picture with matching id
     */
    getPicture: apiEndpointEmptyWithPath<
      PictureModel,
      ProblemDetails,
      PicturesGetPicturePath
    >(
      Method.GET,
      ({ id }: PicturesGetPicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updatePicture: apiEndpointWithPath<
      PictureModel,
      PictureModel,
      ProblemDetails,
      PicturesUpdatePicturePath
    >(
      Method.PUT,
      ({ id }: PicturesUpdatePicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Delete a picture
     */
    deletePicture: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      PicturesDeletePicturePath
    >(
      Method.DELETE,
      ({ id }: PicturesDeletePicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Symbols: {
    /**
     * @summary Gets a list of process map symbol items.
     */
    listSymbols: apiEndpointEmptyUsingQueryParams<
      SymbolsListSymbolsParams,
      ListResponseWithContinuationTokenOfSymbolModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/symbols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Creates a new process map symbol item.
     */
    createSymbol: apiEndpoint<SymbolModel, SymbolModel, ProblemDetails>(
      Method.POST,
      `/api/v1/symbols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets a single process map symbol item.
     */
    getSymbol: apiEndpointEmptyWithPath<
      SymbolModel,
      ProblemDetails,
      SymbolsGetSymbolPath
    >(
      Method.GET,
      ({ id }: SymbolsGetSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Updates a process map symbol item.
     */
    updateSymbol: apiEndpointWithPath<
      SymbolModel,
      SymbolModel,
      ProblemDetails,
      SymbolsUpdateSymbolPath
    >(
      Method.PUT,
      ({ id }: SymbolsUpdateSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes a process map symbol item.
     */
    deleteSymbol: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      SymbolsDeleteSymbolPath
    >(
      Method.DELETE,
      ({ id }: SymbolsDeleteSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  TenantDashboards: {
    /**
     * @summary Gets a list of dashboard items.
     */
    listTenantDashboards: apiEndpointEmptyUsingQueryParams<
      TenantDashboardsListTenantDashboardsParams,
      ListResponseWithContinuationTokenOfTenantDashboardModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/admin/tenant/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Creates a new tenant dashboard.
     */
    createTenantDashboard: apiEndpoint<
      TenantDashboardModel,
      TenantDashboardModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/admin/tenant/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets a single dashboard item.
     */
    getTenantDashboard: apiEndpointEmptyWithPath<
      TenantDashboardModel,
      ProblemDetails,
      TenantDashboardsGetTenantDashboardPath
    >(
      Method.GET,
      ({ id }: TenantDashboardsGetTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Updates a tenant dashboard.
     */
    updateTenantDashboard: apiEndpointWithPath<
      TenantDashboardModel,
      TenantDashboardModel,
      ProblemDetails,
      TenantDashboardsUpdateTenantDashboardPath
    >(
      Method.PUT,
      ({ id }: TenantDashboardsUpdateTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes a tenant dashboard
     */
    deleteTenantDashboard: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      TenantDashboardsDeleteTenantDashboardPath
    >(
      Method.DELETE,
      ({ id }: TenantDashboardsDeleteTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmptyUsingQueryParams<VersionVersionParams, File, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default PresentationAPIGen;
