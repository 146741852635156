import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { validateFormModel } from 'ecto-common/lib/ModelForm/validateForm';
import _ from 'lodash';
import {
  getPathStringFromModelKeyFunc,
  useUpdateModelFormInput
} from 'ecto-common/lib/ModelForm/formUtils';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

// TODO: Remove formModel argument?
function useFormInputWithValidation<
  ObjectType extends object,
  EnvironmentType extends object
>(
  input: ObjectType,
  setInput: Dispatch<SetStateAction<ObjectType>>,
  setIsValidForm: (isValid: boolean) => void,
  models: ModelDefinition<ObjectType, EnvironmentType>[]
) {
  const formModelKeyed = useMemo(() => {
    return _.keyBy(models, (model) => getPathStringFromModelKeyFunc(model.key));
  }, [models]);

  // Validate entire form
  useEffect(() => {
    if (input == null) {
      setIsValidForm(false);
      return;
    }

    const validation = validateFormModel({
      models: formModelKeyed,
      values: input,
      environment: {}
    });

    setIsValidForm(validation.isValid);
  }, [formModelKeyed, setIsValidForm, input]);

  // Update item, and items that has relation to the updated item
  return useUpdateModelFormInput(setInput);
}

export default useFormInputWithValidation;
