import React, { useContext } from 'react';
import ConfirmDeleteDialog, {
  ConfirmDeleteDialogProps
} from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import DataTable, { DataTableColumnProps } from '../DataTable/DataTable';
import TenantContext from '../hooks/TenantContext';
import { NavLink } from 'react-router-dom';
import { getNodePage } from '../utils/commonLinkUtil';
import T from '../lang/Language';
import ActionModal from '../Modal/ActionModal/ActionModal';
import Icons from '../Icons/Icons';
import LayoutDirection from '../types/LayoutDirection';
import Flex, { FlexItem } from '../Layout/Flex';
type CommonNode = { nodeId: string; name: string };

const NodeLinkColumn = ({ node }: { node: CommonNode }) => {
  const { tenantId } = useContext(TenantContext);
  if (node.nodeId == null) {
    return <div>{node.name}</div>;
  }
  return (
    <NavLink to={getNodePage(tenantId, node)} key={'nodelink_' + node.nodeId}>
      {node.name}
    </NavLink>
  );
};

const dependencyColumns: DataTableColumnProps<CommonNode>[] = [
  {
    label: T.common.name,
    dataKey: 'nodeId',
    width: 500,
    minWidth: 100,
    dataFormatter: (_unused: string, node: CommonNode) => (
      <NodeLinkColumn node={node} />
    )
  }
];

export type ConfirmDeleteDialogWithDependenciesBaseProps =
  ConfirmDeleteDialogProps;

const ConfirmDeleteDialogWithDependencies = <ValueType extends object>({
  children,
  itemName,
  isLoading,
  onDelete,
  isOpen,
  onModalClose,
  dependencyItems
}: ConfirmDeleteDialogWithDependenciesBaseProps & {
  dependencyItems: (ValueType & CommonNode)[];
}) => {
  return (
    <>
      {(isOpen && dependencyItems?.length > 0 && (
        <ActionModal
          title={T.resourcedeps.cantdelete.title}
          headerIcon={Icons.Error}
          isLoading={isLoading}
          isOpen={isOpen && dependencyItems?.length > 0}
          onModalClose={onModalClose}
          actionText={T.common.ok}
          onConfirmClick={onModalClose}
          disableCancel
        >
          <Flex direction={LayoutDirection.VERTICAL}>
            <FlexItem>{T.resourcedeps.cantdelete.label}:</FlexItem>
            <FlexItem>
              <DataTable
                data={dependencyItems}
                columns={dependencyColumns}
                isLoading={isLoading}
                disableHeader
              />
            </FlexItem>
          </Flex>
        </ActionModal>
      )) || (
        <ConfirmDeleteDialog
          children={children}
          itemName={itemName}
          isLoading={isLoading}
          onDelete={onDelete}
          isOpen={isOpen}
          onModalClose={onModalClose}
        />
      )}
    </>
  );
};
export default ConfirmDeleteDialogWithDependencies;
