import React, { useCallback, useContext, useEffect } from 'react';
import CRUDView, { useCrudViewData } from 'ecto-common/lib/CRUDView/CRUDView';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import PresentationAPIGen, {
  AssignedTenantDashboardModel,
  TenantDashboardModel
} from 'ecto-common/lib/API/PresentationAPIGen';
import { useMutation } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import NodeTypeOptions from 'ecto-common/lib/NodeTypeOptions/NodeTypeOptions';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import EditButton from 'ecto-common/lib/Button/EditButton';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import NodeTypeProcessMapsRelationsModal from '../ProcessMaps/NodeTypeProcessMapsRelationsModal';
import {
  DashboardsManagementRoute,
  TemplateManagementParams
} from 'js/utils/routeConstants';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import TenantEditDashboardPage from './TenantEditDashboardPage';
import SelectDashboardRelationDialog from './SelectDashboardRelationDialog';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import ConfirmDeleteDialogWithDependencies, {
  ConfirmDeleteDialogWithDependenciesBaseProps
} from 'ecto-common/lib/ConfirmDeleteDialogWithDependencies/ConfirmDeleteDialogWithDependencies';
import { useDependencies } from 'ecto-common/lib/utils/presentationLibrary';

const models: ModelDefinition<TenantDashboardModel>[] = [
  {
    key: (input) => input.name,
    modelType: ModelType.TEXT,
    label: T.common.name,
    placeholder: T.common.name,
    hasError: (input) => _.isEmpty(input)
  },
  {
    key: (input) => input.description,
    modelType: ModelType.TEXT,
    label: T.common.description,
    placeholder: T.common.description
  },
  {
    key: (input) => input.allowedNodeTypes,
    modelType: ModelType.CUSTOM,
    label: T.common.allowednodetypes,
    placeholder: T.common.allowednodetypes,
    hasError: (input) => _.isEmpty(input),

    render: (props, model) => (
      <KeyValueGeneric keyText={model.label}>
        <NodeTypeOptions
          placeholder={model.placeholder}
          nodeTypes={props.rawValue}
          onNodeTypesChanged={props.updateItem}
          hasError={props.hasError}
        />
      </KeyValueGeneric>
    )
  }
];

const createNewItem = (): TenantDashboardModel => ({
  data: '{}'
});

const columns: DataTableColumnProps<TenantDashboardModel>[] = [
  {
    dataKey: 'name',
    label: T.common.name,
    linkColumn: true,
    canSort: true
  },
  {
    dataKey: 'description',
    label: T.common.description,
    canSort: true
  }
];

const DependencyItemDialog = (
  props: ConfirmDeleteDialogWithDependenciesBaseProps & {
    item?: TenantDashboardModel;
  }
) => {
  const assignedItemsQuery =
    PresentationAPIGen.Nodes.getDashboardNodeAssignments.useQuery(
      {
        id: props.item?.id
      },
      {
        enabled: props.isOpen && props.item?.id != null
      }
    );

  const dependencyItems = useDependencies({
    dependencies: assignedItemsQuery.data?.items
  });
  return (
    <ConfirmDeleteDialogWithDependencies<TenantDashboardModel>
      dependencyItems={dependencyItems.nodes}
      {...props}
      isLoading={
        assignedItemsQuery.isLoading ||
        props.isLoading ||
        dependencyItems.isLoading
      }
    />
  );
};

const TenantDashboardsPage = () => {
  useEffect(() => {
    document.title = T.admin.dashboards.title;
  }, []);
  const navigate = useNavigate();
  const { tenantId } = useContext(TenantContext);

  const openEditItemPage = useCallback(
    (item: AssignedTenantDashboardModel) => {
      navigate(
        generatePath(DashboardsManagementRoute.path, {
          tenantId,
          itemId: item.id
        })
      );
    },
    [navigate, tenantId]
  );

  const listQueryHook =
    PresentationAPIGen.TenantDashboards.listTenantDashboards.useInfiniteQuery;

  const { contextSettings } = useContext(TenantContext);

  const updateItemMutation = useMutation({
    mutationFn: (item: TenantDashboardModel) =>
      PresentationAPIGen.TenantDashboards.updateTenantDashboard.promise(
        contextSettings,
        { id: item.id },
        item,
        null
      )
  });

  const createItemMutation =
    PresentationAPIGen.TenantDashboards.createTenantDashboard.useMutation({
      onSuccess: (item) => {
        openEditItemPage(item);
      }
    });

  const deleteItemMutation = useMutation({
    mutationFn: (item: AssignedTenantDashboardModel) => {
      return PresentationAPIGen.TenantDashboards.deleteTenantDashboard.promise(
        contextSettings,
        { id: item.id },
        null
      );
    }
  });
  const [isEditRelationsOpen, showEditRelations, hideEditRelations] =
    useSimpleDialogState();

  const crudData = useCrudViewData({
    listQueryHook,
    searchItems: ['name'],
    sortBy: 'name'
  });

  const toolbarItems = (
    <>
      <ToolbarItem>
        <EditButton onClick={showEditRelations}>
          {T.admin.processmaps.editnodetyperelation}...
        </EditButton>
      </ToolbarItem>
    </>
  );

  const params = useParams<TemplateManagementParams>();
  if (params.itemId != null) {
    return (
      <TenantEditDashboardPage
        dashboardId={params.itemId}
        parentLink={generatePath(DashboardsManagementRoute.path, { tenantId })}
        parentName={T.admin.dashboards.title}
      />
    );
  }

  return (
    <>
      <CRUDView
        models={models}
        columns={columns}
        createNewItem={createNewItem}
        itemName={'name'}
        title={T.admin.dashboards.title}
        editTitle={T.admin.dashboards.edit}
        addTitle={T.admin.dashboards.add}
        deleteItemMutation={deleteItemMutation}
        updateItemMutation={updateItemMutation}
        createItemMutation={createItemMutation}
        toolbarItems={toolbarItems}
        onClickRow={openEditItemPage}
        {...crudData}
        confirmDeleteDialog={DependencyItemDialog}
      />
      <NodeTypeProcessMapsRelationsModal
        isOpen={isEditRelationsOpen}
        onModalClose={hideEditRelations}
        itemSelectComponent={SelectDashboardRelationDialog}
      />
    </>
  );
};

export default TenantDashboardsPage;
