import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import {
  ProcessMapRect,
  ProcessMapTextAlignment,
  ProcessMapTextAlignments,
  ProcessMapTextSettings
} from '../ProcessMapViewConstants';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import colors from 'ecto-common/lib/styles/variables/colors';

export const xModel = (
  rectIndex: number
): ModelDefinition<ProcessMapRect[]> => ({
  label: T.admin.processmaps.objecteditor.x,
  key: (input) => input[rectIndex].centerX,
  modelType: ModelType.NUMBER,
  isHorizontal: true
});

export const yModel = (
  rectIndex: number
): ModelDefinition<ProcessMapRect[]> => ({
  label: T.admin.processmaps.objecteditor.y,
  key: (input) => input[rectIndex].centerY,
  modelType: ModelType.NUMBER,
  isHorizontal: true
});

export const textPointSections: ModelFormSectionType<ProcessMapRect[]>[] = [
  {
    label: T.admin.processmaps.objecteditor.dimensions,
    lines: [
      {
        models: [xModel(0), yModel(0)]
      }
    ]
  }
];

export const rectPointsSections: ModelFormSectionType<ProcessMapRect[]>[] = [
  {
    label: T.admin.processmaps.objecteditor.dimensions,
    lines: [
      {
        models: [xModel(0), yModel(0)]
      },
      {
        models: [
          {
            label: T.admin.processmaps.objecteditor.width,
            key: (input) => input[0].width,
            modelType: ModelType.NUMBER,
            isHorizontal: true
          },
          {
            label: T.admin.processmaps.objecteditor.height,
            key: (input) => input[0].height,
            modelType: ModelType.NUMBER,
            isHorizontal: true
          }
        ]
      }
    ]
  }
];

const processMapTextAlignmentOptions: GenericSelectOption<ProcessMapTextAlignment>[] =
  Object.values(ProcessMapTextAlignments).map((alignment) => ({
    label: T.admin.processmaps.objecteditor.textalignments[alignment],
    value: alignment
  }));

type ObjectWithTextAlignment = {
  textAlignment?: ProcessMapTextAlignment;
};

export const textAlignmentOption: ModelDefinition<ObjectWithTextAlignment> = {
  label: T.admin.processmaps.objecteditor.textalignment,
  key: (input) => input.textAlignment,
  modelType: ModelType.OPTIONS,
  options: processMapTextAlignmentOptions,
  placeholder:
    T.admin.processmaps.objecteditor.textalignments[
      ProcessMapTextAlignments.Left
    ],
  isClearable: true,
  isHorizontal: true
};

export const textStyleSections = <ObjectType extends object>(
  label: string,
  keyPrefix: (input: ObjectType) => ProcessMapTextSettings,
  textSettings: ProcessMapTextSettings
): ModelFormSectionType<ObjectType>[] => {
  return [
    {
      label,
      lines: [
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.fontsize,
              key: (input) => keyPrefix(input).fontSize,
              modelType: ModelType.NUMBER,
              placeholder: textSettings.fontSize + '',
              isHorizontal: true
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.bold,
              key: (input) => keyPrefix(input).bold,
              modelType: ModelType.BOOL,
              placeholderChecked: textSettings.bold
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.italic,
              key: (input) => keyPrefix(input).italic,
              modelType: ModelType.BOOL,
              placeholderChecked: textSettings.italic
            }
          ]
        },
        {
          models: [
            {
              label: T.common.color,
              key: (input) => keyPrefix(input).textColor,
              modelType: ModelType.COLOR,
              placeholder: textSettings.textColor ?? colors.surface2Color
            }
          ]
        }
      ]
    }
  ];
};
