import { HorizontalLabelValueWeights } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import React from 'react';
import { ModelEditorProps } from './ModelEditor';
import { OdataListOptionsModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorODataListOptions';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { NodeModelDefinition } from './Plugins/ModelEditorNode';
import { NodeListModelDefinition } from './Plugins/ModelEditorNodeList';
import {
  DisabledTextModelDefinition,
  TextModelDefinition
} from 'ecto-common/lib/ModelForm/Plugins/ModelEditorText';
import { ColorModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorColor';
import { NumberModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorNumber';
import { SignalModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSignal';
import { SignalTypeModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSignalType';
import { LabelModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorLabel';
import { OptionsModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorOptions';
import { CheckboxOptionsModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorCheckboxOptions';
import { BoolModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorBool';
import { ButtonModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorButton';
import { RadioButtonModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorRadioButton';
import { TableModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorTable';
import { SpaceModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSpace';
import { EquipmentModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorEquipment';
import { DateModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorDate';
import { SignalListModelDefinition } from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSignalList';
import { FileModelDefinition } from './Plugins/ModelEditorFile';

export type ModelBoolFunctionProperty<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
> = (
  value: ValueType,
  input: ObjectType,
  environment: EnvironmentType,
  model: ModelDefinition<ObjectType, EnvironmentType>
) => boolean;
export type ModelBoolFunctionRootProperty<
  ObjectType extends object,
  EnvironmentType extends object
> = (
  input: ObjectType,
  environment: EnvironmentType,
  model: ModelDefinition<ObjectType, EnvironmentType>
) => boolean;
export type ModelDynamicBoolRootProperty<
  ObjectType extends object,
  EnvironmentType extends object
> = boolean | ModelBoolFunctionRootProperty<ObjectType, EnvironmentType>;
export type ModelDynamicBoolProperty<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
> = boolean | ModelBoolFunctionProperty<ObjectType, EnvironmentType, ValueType>;
export type ModelStringFunctionProperty<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
> = (
  value: ValueType,
  input: ObjectType,
  environment: EnvironmentType,
  model: ModelDefinition<ObjectType, EnvironmentType>
) => string;
export type ModelNodeFunctionProperty<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
> = (
  value: ValueType,
  input: ObjectType,
  environment: EnvironmentType,
  model: ModelDefinition<ObjectType, EnvironmentType>
) => React.ReactNode;
export type ModelDynamicStringProperty<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
> =
  | string
  | ModelStringFunctionProperty<ObjectType, EnvironmentType, ValueType>;
export type ModelDynamicNodeProperty<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
> =
  | React.ReactNode
  | ModelNodeFunctionProperty<ObjectType, EnvironmentType, ValueType>;
export type ModelDynamicOptionsProperty<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
> =
  | GenericSelectOption<ValueType>[]
  | ((
      value: ValueType,
      input: ObjectType,
      environment: EnvironmentType,
      model: ModelDefinition<ObjectType, EnvironmentType>
    ) => GenericSelectOption<ValueType>[]);

export type ModelDefinitionInternal<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
> = {
  // This method gives the ability to trigger updates to other items in the
  // same form.
  onDidUpdate?: (
    name: string[],
    value: ValueType,
    input: ObjectType,
    environment: EnvironmentType
  ) => [key: (path: ObjectType) => unknown, value: unknown][];

  // Calling onDidUpdate can sometimes cause recursive updates to items. Setting this to true
  // prevents this behavior
  preventRecursiveOnDidUpdate?: boolean;

  // The key into the items input
  key: (path: ObjectType) => ValueType;

  // Whether the item is available for input
  enabled?: ModelDynamicBoolRootProperty<ObjectType, EnvironmentType>;
  // Whether the item should be rendered or not
  visible?: ModelDynamicBoolRootProperty<ObjectType, EnvironmentType>;
  // Text to display in the context where the item value is displayed
  label?: string;
  // A placeholder text to show if the item value does not have a value
  placeholder?: string;
  // A helpful text explaining the input control
  helpText?: ModelDynamicNodeProperty<ObjectType, EnvironmentType, ValueType>;
  // More helpful text describing error
  errorText?: ModelDynamicNodeProperty<ObjectType, EnvironmentType, ValueType>;

  // Whether the input has error, e.g display as invalid input error
  // for those inputs that support showing errors
  hasError?: ModelDynamicBoolProperty<ObjectType, EnvironmentType, ValueType>;

  // Whether this option type is multiselect
  isMultiOption?: boolean;

  // A method that transforms the value into a more readable string that will be
  // embedded in the editor

  // Used by:
  // label, => string to string, no problem
  // table,
  // radio (HardwareSettingsModel), used to return bool for if selected,
  // text (for disabled text), - create specific component for this

  // Whether the value of the model is currently being fetched
  isLoading?:
    | boolean
    | ((
        value: ValueType,
        input: ObjectType,
        environment: EnvironmentType,
        model: ModelDefinition<ObjectType, EnvironmentType>
      ) => boolean);

  // Can be set for a spcific model to override the default
  useTooltipHelpTexts?: boolean;

  // Whether or not to lay out the editor horizontally
  isHorizontal?: boolean;

  // If laying out horizontally, this can be used to set the relative sizess of the label and value
  horizontalWeights?: HorizontalLabelValueWeights;
};

export type ModelTableData<ObjectType> = {
  data: ObjectType[];
  columns: DataTableColumnProps<ObjectType>[];
  isLoading: boolean;
  disableHeader?: boolean;
};

export type CustomModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object,
  ValueType = unknown
> = {
  modelType: typeof ModelType.CUSTOM;
  render: (
    props: ModelEditorProps<EnvironmentType, ValueType>,
    model: CustomModelDefinition<ObjectType, EnvironmentType, ValueType>,
    input: ObjectType,
    environment: EnvironmentType
  ) => React.ReactNode;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, ValueType>;

/**
 * Metadata, on a property of an object, that helps
 * creating editing or viewing components for the object.
 * Used with ModelForm, ModelEditor, and ModelDisplay.
 */
export type ModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object,
  ValueType = unknown
> =
  | TextModelDefinition<ObjectType, EnvironmentType>
  | DisabledTextModelDefinition<ObjectType, EnvironmentType>
  | ColorModelDefinition<ObjectType, EnvironmentType>
  | NumberModelDefinition<ObjectType, EnvironmentType>
  | SignalModelDefinition<ObjectType, EnvironmentType>
  | SignalListModelDefinition<ObjectType, EnvironmentType>
  | SignalTypeModelDefinition<ObjectType, EnvironmentType>
  | LabelModelDefinition<ObjectType, EnvironmentType>
  | OptionsModelDefinition<ObjectType, EnvironmentType, ValueType>
  | CheckboxOptionsModelDefinition<ObjectType, EnvironmentType, ValueType>
  | BoolModelDefinition<ObjectType, EnvironmentType>
  | ButtonModelDefinition<ObjectType, EnvironmentType>
  | RadioButtonModelDefinition<ObjectType, EnvironmentType, ValueType>
  | TableModelDefinition<ObjectType, EnvironmentType>
  | SpaceModelDefinition<ObjectType, EnvironmentType>
  | EquipmentModelDefinition<ObjectType, EnvironmentType>
  | NodeModelDefinition<ObjectType, EnvironmentType>
  | NodeListModelDefinition<ObjectType, EnvironmentType>
  | OdataListOptionsModelDefinition<ObjectType, EnvironmentType>
  | DateModelDefinition<ObjectType, EnvironmentType>
  | FileModelDefinition<ObjectType, EnvironmentType>
  | CustomModelDefinition<ObjectType, EnvironmentType, ValueType>;

export type ModelMapItem<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  key: string;
  model: ModelDefinition<ObjectType, EnvironmentType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateItem: (newValue: any) => void;
  isLoading: boolean;
  isHorizontal: boolean;
};

export enum ModelFormSectionStyle {
  SECTION_LIST = 'SECTION_LIST',
  SEGMENT_CONTROL = 'SEGMENT_CONTROL',
  SELECT_CONTROL = 'SELECT_CONTROL'
}

export type ModelFormLineType<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  models?: ModelDefinition<ObjectType, EnvironmentType>[];
  // For nested sections
  lines?: ModelFormLineType<ObjectType, EnvironmentType>[];

  horizontalLabels?: boolean;
};

export type ModelFormSectionType<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  label?: React.ReactNode;
  visible?: (input: ObjectType) => boolean;
  menuActions?: React.ReactNode;
  wrapContent?: boolean;
  initiallyCollapsed?: boolean;
  listPriority?: number;
  // Either specify models as list of lines with multiple models per line
  lines?: ModelFormLineType<ObjectType, EnvironmentType>[];
  // Or just as a long list of models, laid out in a list with one model per line
  models?: ModelDefinition<ObjectType, EnvironmentType>[];

  subSectionsStyle?: ModelFormSectionStyle;
  // Sub sections in the section
  sections?: ModelFormSectionType<ObjectType, EnvironmentType>[];
  hasError?: boolean;

  // If set, the section label will not be visible on top. Rarely useful.
  hideLabel?: boolean;

  helpText?: string;

  footerText?: string;
};

export class ModelBuilder<
  ObjectType extends object,
  EnvironmentType extends object = object
> {
  private _models: ModelDefinition<ObjectType, EnvironmentType, unknown>[] = [];

  public push<ValueType>(
    value: ModelDefinition<ObjectType, EnvironmentType, ValueType>
  ) {
    this._models.push(value);
    return this;
  }

  public get models() {
    return this._models;
  }
}

// Used for functions that do not require detailed type information
export type BaseModelDefinition = ModelDefinition<object, object, unknown>;
export type BaseModelSectionType = ModelFormSectionType<object, object>;
