import classNames from 'classnames';
import styles from 'ecto-common/lib/DataTable/DataTable.module.css';
import SortIndicator from 'ecto-common/lib/DataTable/SortIndicator';
import React, { useCallback } from 'react';
import { SortDirectionType } from './SortDirection';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import { Property } from 'csstype';

interface DataTableHeaderCellProps {
  columnData?: {
    canSort?: boolean;
    disableSort?: boolean;
    label?: React.ReactNode;
    dataKey?: string;
    tooltip?: React.ReactNode;
  };
  onSortChange?({ sortBy }: { sortBy: string }): void;
  sortBy?: string;
  sortDirection?: SortDirectionType;
  align?: string;
}

const DataTableHeaderCell = ({
  columnData,
  onSortChange,
  sortBy,
  sortDirection,
  align
}: DataTableHeaderCellProps) => {
  const { canSort, disableSort, dataKey } = columnData;
  const enableSorting = !!onSortChange && !disableSort && canSort;
  const isSortColumn = sortBy === columnData.dataKey;
  const classes = classNames(
    styles.headerCell,
    enableSorting && styles.sortableHeaderCell,
    disableSort && styles.disableSort
  );

  const onClick = useCallback(() => {
    if (enableSorting) {
      onSortChange({ sortBy: dataKey });
    }
  }, [enableSorting, onSortChange, dataKey]);

  let innerContent = columnData.label;

  if (columnData.tooltip) {
    innerContent = (
      <Tooltip text={columnData.tooltip} multiline withIcon>
        {columnData.label}
      </Tooltip>
    );
  }

  return (
    <div
      className={classes}
      onClick={onClick}
      style={{
        justifyContent: align,
        textAlign: align as Property.TextAlign
      }}
    >
      {innerContent}
      {!disableSort && isSortColumn && (
        <SortIndicator sortDirection={sortDirection} />
      )}
    </div>
  );
};

export default React.memo(DataTableHeaderCell);
